//*==================================================================*
//*             DL COM E IND DE PRODUTOS ELETRONICOS LTDA            *
//*------------------------------------------------------------------*
//*    PROGRAMA....: HEADER MODEL                                    *
//*    PROGRAMADOR.: EDER GUIMARAES RODRIGUES  - DL                  *
//*------------------------------------------------------------------*
//*    OBJETIVO....: HEADER COM LOGO DL E MI                         *
//*------------------------------------------------------------------*
//*    IMPORTS.....:                                                 *
import React from 'react';
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMG IMPORTS.:                                                 *
import dl from '../../assets/image/dl.png';
import mi from '../../assets/image/Xiaomi_logo_(2021-).svg.png';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS.:                                                 *
import './css/Style.css';
//*                                                                  *
//*==================================================================*
//*                                                                  *
class Header extends React.Component<{}, {}> {
    render() { 
        return (
            <section className="MD-header">
                <div className="MD-box-logo">
                    <a href="https://dl.com.br/"
                        target="_blank"
                        rel="noreferrer">
                        <img src={dl}
                            className="MD-brand-logo"
                            alt="DL_Logo" />
                    </a>
                </div>
                <div className="MD-box-logo">
                    <a href="https://mibrasil.com.br/"
                        target="_blank"
                        rel="noreferrer">
                        <img src={mi}
                            className="MD-brand-logo"
                            alt="MI_Logo" />
                    </a>
                </div>
                <div className="MD-box-logo"></div>
            </section>
        );
    }
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Header;
//*                                                                  *
//*==================================================================*